<template>
  <div>
    <headertop ></headertop>
  
    <goodslist></goodslist>
  </div>
</template>

<script>
import headertop from "../components/header.vue";
import goodslist from "../components/goodslist.vue";
export default {
  name: "home",
  components: {
    headertop,
    goodslist
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},

  methods: {},
};
</script>
